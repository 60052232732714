import React, { useContext } from "react";
import useMousePosition from "./useMousePosition";
import { CursorContext } from "./App";

export const Cursor = () => {
  const { clientX, clientY } = useMousePosition();
  const [cursor] = useContext(CursorContext);

  
  return (
    <div  style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, 
                    zIndex: 9999, pointerEvents: "none" }} >
      <svg width={50} height={50} 
      // viewBox="0 0 50 50" 
      style={{ position: "absolute", left: clientX,
                                                            top: clientY, transform: "translate(-50%, -50%)", }}>
        <polygon points="50,100 30,30 0,90 " stroke={cursor.active ? 'white' : "black"} strokeWidth="4" fill="transparent"/>
        {/* {cursor.visible ? 
          <text x="50%" y="50%" textAnchor="middle" stroke="black" strokeWidth="1px" dy=".3em">scroll</text>
        : null} */}
      </svg>
    </div>
  );
};
