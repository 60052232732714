import * as THREE from 'three'
import { forwardRef, useRef, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Image, Text, useScroll, } from '@react-three/drei'

function Item({ index, hovered, setHovered, position, scale, c = new THREE.Color(), ...props }) {
  const ref = useRef()
  const over = () => setHovered(index);
  const out = () => setHovered(index);
  const scroller = useScroll()

  useFrame((state, delta) => {
    ref.current.material.grayscale = THREE.MathUtils.damp(ref.current.material.grayscale, hovered === index ? 0 : Math.max(0, 1 - 0.2), 6, delta)
    ref.current.material.zoom = 0.9 + scroller.range(19 / 24, 20 / 24) / 2
  })

  return <Image ref={ref} {...props} position={position} scale={scale} onPointerOver={over} onPointerOut={out} />
}

export const Projects = forwardRef(({ title, details, mobile, images, w = 4, gap = 2.25 }, ref)  => {
  const xW = w + gap
  const [hovered, setHovered] = useState(0)
  const { width, height } = useThree((state) => state.viewport)

  if (mobile) {
    var scaleX = 1
    var scaleY = 0.6
  } else if (width <= 11) {
    scaleX = w-1
    scaleY = 1.7
  } else if (width <= 12.7) {
    scaleX = w
    scaleY = 2.2
  } else {
    scaleX = w+2
    scaleY = 2.9
  }

  return (
    <group ref={ref}>
      {images.map((url, i) => <Item key={i} hovered={hovered} setHovered={setHovered} index={i} position={[mobile ? -0.3 + i / 1.7 : -3.1 + i * xW , mobile ? -i + 0.48 : -i , 0]} scale={[scaleX, scaleY , 1]} url={url} />) }
      <Text fontSize={mobile ? 0.3 : 1} letterSpacing={-0.06} font="/IsotextProLight.otf" color='#140044' position={[mobile ? -0.4 : -w/1.3, mobile ? 0 : -2.2, 2]}>
        {title}
      </Text>
      <Text fontSize={mobile ? 0.06 : 0.16} letterSpacing={-0.06} font="/IsotextProLight.otf" color='#222222' position={[mobile ? -0.25 : -w/1.4, mobile ? -0.13 : -2.6, 2]}>
        {details} 
      </Text>

    </group>
  )
}
)