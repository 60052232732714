import React, { useEffect, useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { Text} from '@react-three/drei'
import * as THREE from 'three'
import Random from 'react-animated-text'

export const LightText = () => {

    return (
        <group position={[0, 18, 3]}>
            <Text fontSize={4} letterSpacing={-0.06} font="/IsotextProLight.otf" color='#140044' rotation={[0, 0, 0.1]}>
            Creating unique 
            <meshBasicMaterial metalness={1}/>
            </Text>
            <Text fontSize={4} letterSpacing={-0.06} font="/IsotextProLight.otf" position={[0, -5, 0]} color='#0f0f0f' >
            experiences is our thing
            <meshBasicMaterial metalness={1}/>
            </Text>
        </group>
    )
}

export const LightText1 = () => {
   
    return (
        <group position={[0 , 5, 0]}>
            <Text fontSize={1.5} letterSpacing={-0.06} font="/IsotextProLight.otf" color='#222222'>
                A software house providing extraordinary applications
                <meshBasicMaterial metalness={1}/>
            </Text>
        </group>
    )
}

    
      
export const Brackets = () => {
    const ref  = useRef()
    let theta = 0
    useFrame(() => {
        const r = 5 * Math.sin(THREE.Math.degToRad((theta += 0.05)))
        ref.current.children.forEach((child, index) => {
            child.position.z -= 4 * theta
        })
    })
  
    const coords = new Array(1000).fill().map((i) => [Math.random() * 800 - 400, Math.random() * 800 - 100, Math.random() * 800 - 400]) 
    coords.sort(function(a, b) {return Math.sqrt( (a[0]-b[0])^2 + (a[1]-b[1])^2 + (a[2]-b[2])^2 )})
  
    return (
        <group ref={ref} >
            {coords.map(([p1, p2, p3], i) => (
                <group key={i}>
                    <mesh position={[p1, p2, p3]} rotation={[0, 0, -Math.PI/10]}>
                        <boxGeometry attach="geometry" args={[1, 30, 30]} position={[p1, p2, p3]} />
                        <meshStandardMaterial attach="material" color="#0f0f0f" />
                    </mesh>
                </group>
            ))}
        </group>
    )
  }
    
  
   