import React, {useState, useContext} from 'react';
import {Backdrop, Box, Modal, Fade, IconButton, Link, Grid} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {AiOutlineFacebook, AiOutlineLinkedin} from 'react-icons/ai'
import {CursorContext} from './App'
import { Services } from './Services';
import GetRenders from './util';


const style = {
  position: 'fixed',
  top: '0.4%',
  borderRadius: '5px',
  left: '0.5%',
  width: '100%',
  height: '99.2%',
  bgcolor: '#222222',
  color: 'white',
  boxShadow: 24,
  p: 4,
};

export default function FadeMenu({langEn, setLangEn, scroller, height, op, setOp}) {
  const [open, setOpen] = useState(false);
  const handle = () => {setOpen(!open); setOp(!op); setCursor(({ active }) => ({ active: !active }))};
  const [, setCursor] = useContext(CursorContext);
  const mobile = GetRenders().XS
  if (scroller) { 
    var targetPixels = scroller.el['scrollHeight'] - height
  }

  if (mobile) {
      return (
        <div style={{position: 'absolute', display: 'flex', top: 0, width: '96%'}}>
            <img src='/LogoFinal.png' style={{width: '63vw', maxWidth: 270}}/>
            {/* <img src='/greek.png' onClick={() => setLangEn(0)} className='icon' style={{position: 'absolute', right: 60, marginTop: 14, width: 20}}/>
            <img src='/eng.png' onClick={() => setLangEn(1)} className='icon' style={{position: 'absolute', right: 45, marginTop: 13, width: 20}}/> */}
            <IconButton aria-expanded={open ? 'true' : undefined} onClick={handle} style={{position: 'absolute', right: -10, top: 0, color: '#2E0099'}}>
                <MenuIcon style={{paddingBottom: 5}}/>
            </IconButton>
            <Modal open={open} onClose={handle} closeAfterTransition BackdropComponent={Backdrop} 
                BackdropProps={{ timeout: 500 }} >
                <Fade in={open}>
                    <Box sx={style}>
                        <img src='/SimaWhite.png' style={{width: 20, top: 10, left: 10, position: 'absolute'}}/>
                        <AiOutlineFacebook size={20} onClick={() =>  window.open('https://www.facebook.com/Solutionist-103597762162401', '_blank')} className='icon' style={{position: 'absolute', left: 5, bottom: 5}}/>
                        <AiOutlineLinkedin size={20} onClick={() =>  window.open('https://www.linkedin.com/company/solutionist-gr/about/?viewAsMember=true', '_blank')} className='icon' style={{position: 'absolute', left: 25, bottom: 5}}/>
                        <IconButton aria-expanded={open ? 'true' : undefined} onClick={handle} style={{color: 'white', position: 'absolute', right: 3, top: -2}}>
                            <MenuIcon style={{paddingBottom: 5}}/>
                        </IconButton>
                        <Grid container>
                            <Grid item xs={12} style={{marginTop: '2vh', borderBottom: '1px solid #d3d3d3', height: '50vh', color: '#d3d3d3'}}>
                                <Link variant="h4" component="h4" onClick={() => {scroller.el.scrollTo({top: 0 * targetPixels}); handle()}}>
                                    {langEn ? 'Home' : 'Κεντρική' }
                                </Link>
                                <Link variant="h4" component="h4" onClick={() => {scroller.el.scrollTo({top: 0.12 * targetPixels}); handle()}}>
                                    {langEn ? 'Services' : 'Υπηρεσίες' }
                                </Link>
                                <Link variant="h4" component="h4" onClick={() => {scroller.el.scrollTo({top: 0.61 * targetPixels}); handle()}}>
                                    {langEn ? 'Collabs' : 'Συνεργάτες' }
                                </Link>
                                <Link variant="h4" component="h4" onClick={() => {scroller.el.scrollTo({top: 0.65 * targetPixels}); handle()}}>
                                    {langEn ? 'Why us' : 'Γιατί εμάς' }
                                </Link>
                                <Link variant="h4" component="h4" onClick={() => {scroller.el.scrollTo({top: 0.74 * targetPixels}); handle()}}>
                                    {langEn ? 'Works' : 'Έργα' }
                                </Link>
                                <Link variant="h4" component="h4" onClick={() => {scroller.el.scrollTo({top: 0.97 * targetPixels}); handle()}}>
                                    {langEn ? 'Contact' : 'Επικοινωνία' }
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <h2 style={{fontSize: '1rem', textAlign: 'justify', color: '#d3d3d3', marginTop: 20, marginRight: 10}}> 
                                    {langEn ? 'We design and develop web apps, websites, mobile apps and ml apps.' : 'Σχεδιάζουμε και υλοποιούμε εφαρμογές διαδικτύου, κινητού, τεχνητής νοημοσύνης καθώς και ιστοσελίδες και eshops.'}
                                </h2>
                                <Grid container style={{color: 'gray', marginTop: -20, marginLeft: -30}}>
                                    <Grid item xs={4}>
                                        <h2 style={{color: '#d3d3d3', fontSize: '0.7rem'}}> {langEn ? "Design" : 'Σχεδίαση'}</h2>
                                        <h2 style={{color: '#d3d3d3', fontSize: '0.7rem'}}> {langEn ? 'Marketing' : 'Μάρκετινγκ'}</h2>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <h2 style={{color: '#d3d3d3', fontSize: '0.7rem'}}> {langEn ? "Code" : 'Κώδικας'}</h2>
                                        <h2 style={{color: '#d3d3d3', fontSize: '0.7rem'}}> {langEn ? 'Support' : 'Υποστήριξη'}</h2>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
      )
  } else {
    return (
        <div style={{position: 'absolute', display: 'flex', top: 0, width: '100%'}}>
            <img src='/LogoFinal.png' style={{width: '63vw', maxWidth: 270}}/>
            {/* <img src='/greek.png' onClick={() => setLangEn(0)} className='icon' style={{position: 'absolute', right: 90, marginTop: 14, width: 20}}/>
            <img src='/eng.png' onClick={() => setLangEn(1)} className='icon' style={{position: 'absolute', right: 65, marginTop: 13, width: 20}}/> */}
            <IconButton aria-expanded={open ? 'true' : undefined} onClick={handle} style={{position: 'absolute', right: 0, color: '#2E0099'}}>
                <MenuIcon/>
            </IconButton>
            <Modal open={open} onClose={handle} closeAfterTransition BackdropComponent={Backdrop} 
                BackdropProps={{ timeout: 500 }} >
                <Fade in={open}>
                    <Box sx={style}>
                        <img src='/SimaWhite.png' style={{width: 40, top: 10, left: 10, position: 'absolute'}}/>
                        <AiOutlineFacebook size={30} onClick={() =>  window.open('https://www.facebook.com/Solutionist-103597762162401', '_blank')} className='icon' style={{position: 'absolute', left: 10, bottom: 20}}/>
                        <AiOutlineLinkedin size={30} onClick={() =>  window.open('https://www.linkedin.com/company/solutionist-gr/about/?viewAsMember=true', '_blank')} className='icon' style={{position: 'absolute', left: 45, bottom: 20}}/>
                        <IconButton aria-expanded={open ? 'true' : undefined} onClick={handle} style={{color: 'white', position: 'absolute', right: 10, top: -3}}>
                            <MenuIcon/>
                        </IconButton>
                        <Grid container style={{marginTop: 30}}>
                            <Grid item lg={1} ></Grid>
                            <Grid item lg={4} md={5} sm={5} xs={12} style={{marginTop: '2vh', borderRight: '1px solid #d3d3d3', height: '80vh', color: '#d3d3d3'}}>
                                <Link variant="h1" component="h1" onClick={() => {scroller.el.scrollTo({top: 0 * targetPixels}); handle()}}>
                                    {langEn ? 'Home' : 'Κεντρική' }
                                </Link>
                                <Link variant="h1" component="h1" onClick={() => {scroller.el.scrollTo({top: 0.12 * targetPixels}); handle()}}>
                                    {langEn ? 'Services' : 'Υπηρεσίες' }
                                </Link>
                                <Link variant="h1" component="h1" onClick={() => { scroller.el.scrollTo({top: 0.6 * targetPixels}); handle()}}>
                                    {langEn ? 'Collabs' : 'Συνεργάτες' }
                                </Link>
                                <Link variant="h1" component="h1" onClick={() => {scroller.el.scrollTo({top: 0.68 * targetPixels}); handle()}}>
                                    {langEn ? 'Why us' : 'Γιατί εμάς' }
                                </Link>
                                <Link variant="h1" component="h1" onClick={() => {scroller.el.scrollTo({top: 0.76 * targetPixels}); handle()}}>
                                    {langEn ? 'Works' : 'Έργα' }
                                </Link>
                                <Link variant="h1" component="h1" onClick={() => {scroller.el.scrollTo({top: 0.97 * targetPixels}); handle()}}>
                                    {langEn ? 'Contact' : 'Επικοινωνία' }
                                </Link>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6}>
                                <h2 style={{fontSize: '3rem', color: '#d3d3d3', marginTop: 10, marginLeft: 20}}> 
                                    {langEn ? 'We design and develop web apps, websites, mobile apps and ml apps.' : 'Σχεδιάζουμε και υλοποιούμε εφαρμογές διαδικτύου, κινητού, τεχνητής νοημοσύνης καθώς και ιστοσελίδες και eshops.'}
                                </h2>
                                <Grid container style={{color: 'gray'}}>
                                    <Grid item lg={3} md={3} sm={3}>
                                        <h2 style={{color: '#d3d3d3'}}> {langEn ? "Design" : 'Σχεδίαση'}</h2>
                                        <p style={{fontSize: '1.2rem'}}> {langEn ? '3D Design' : '3D'}</p>
                                        <p style={{fontSize: '1.2rem'}}> {langEn ? 'Graphic Design' : 'Γραφικά'}</p>
                                        <p style={{fontSize: '1.2rem'}}> {langEn ? 'UI/UX Design' : 'UI/UX' }</p>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3}>
                                        <h2 style={{color: '#d3d3d3'}}> {langEn ? "Code" : 'Κώδικας'}</h2>
                                        <p style={{fontSize: '1.2rem'}}> {langEn ? 'Optimized' : 'Αποδοτικός'}</p>
                                        <p style={{fontSize: '1.2rem'}}> {langEn ? 'Fast' : 'Γρήγορος'}</p>
                                        <p style={{fontSize: '1.2rem'}}> {langEn ? 'Secure' : 'Ασφαλής'} </p>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3}>
                                        <h2 style={{color: '#d3d3d3'}}> {langEn ? 'Marketing' : 'Μάρκετινγκ'}</h2>
                                        <p style={{fontSize: '1.2rem'}}>{langEn ? 'Branding' : 'Διαφήμιση'}</p>
                                        <p style={{fontSize: '1.2rem'}}>{langEn ? 'Analytics' : 'Αναλύσεις'}</p>
                                        <p style={{fontSize: '1.2rem'}}>{langEn ? 'Campaigns' : 'Καμπάνιες'} </p>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3}>
                                        <h2 style={{color: '#d3d3d3'}}> {langEn ? 'Support' : 'Υποστήριξη'}</h2>
                                        <p style={{fontSize: '1.2rem'}}>{langEn ? 'Repair' : 'Επισκευή'}</p>
                                        <p style={{fontSize: '1.2rem'}}>{langEn ? 'Maintenance' : 'Διατήρηση'}</p>
                                        <p style={{fontSize: '1.2rem'}}>{langEn ? 'Improvement' : 'Βελτίωση'} </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
    }
}

