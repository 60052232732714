import React, {forwardRef} from 'react'
import { Html } from '@react-three/drei'


export const Who = forwardRef(({ mobile, ...props}, ref) => {    

    return (
        <Html center ref={ref} className='who' {...props}>
            <div style={{marginTop: '2vh', marginLeft: '10vw',textAlign: 'justify'}}> 
                    <h1 > Why us </h1>
                    <h2 style={{pointerEvents: 'none', fontSize: mobile ? '1rem' : '1.5vw'}}> 
                    Solutionist is a team of young people with one goal: to solve customers' problems using state of the art technology, writing optimized code, and creating extraordinary, high-impact applications.
                        <br/>
                    </h2>
                    {!mobile ? 
                        <h2 style={{fontSize: mobile ? '1rem' : '1.5vw'}}>
                            Our philosophy involves diving deeply into the business sector of the client’s company and then proposing and developing the appropriate, most efficient solutions dedicated to the company. We are eager to constantly learn and expand our knowledge base - technical and non technical - to achieve the best outcome for the client.
                        </h2>
                    : null}
                    <h2 style={{fontSize: mobile ? '1rem' : '1.5vw'}}>
                    We work with companies across multiple business sectors, to create <strong>custom applications for web and mobile</strong>, <strong>machine learning applications</strong> and <strong>3D website experiences</strong>. 
                    We offer complete solutions from UI-UX design, software development, project management to marketing and social media campaigns. We also collaborate with startups and innovators to assist them in creating unique, innovative products.
                    </h2>
                    {/* <h2 style={{fontSize: '2vw', color: '#2E0099'}}> OUR values</h2>
                    <h2 style={{fontSize: '1.3vw', textAlign: 'justify'}}> 
                        <BsCheckLg style={{color: '#2E0099', marginRight: 10}} />
                        We believe change is the most important aspect of evolving. For us change means the ability to observe, doubt and learn.
                    </h2>
                    <h2 style={{fontSize: '1.3vw', textAlign: 'justify'}}> 
                    <BsCheckLg style={{color: '#2E0099', marginRight: 10}} />
                    We always work hard and fast but not careless. We believe that understanding deeply the foundations of a problem leads to quick and optimized solutions. 
                    </h2>
                    <h2 style={{fontSize: '1.3vw', textAlign: 'justify'}}> 
                    <BsCheckLg style={{color: '#2E0099', marginRight: 10}} />
                    We challenge ourselves continually, and search for the most effective way to solve a problem, which is not always the easiest.
                    </h2>
                    <h2 style={{fontSize: '1.3vw', textAlign: 'justify'}}> 
                    <BsCheckLg style={{color: '#2E0099', marginRight: 10}} />
                    We think that you are not just a customer but a partner.
                    </h2> */}
            </div>
        </Html>
    )
})
