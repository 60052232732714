import * as THREE from 'three'
import React, { forwardRef, useState, Suspense } from 'react'
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber'
import { Html, useGLTF, softShadows, ScrollControls, useScroll, useTexture, Text } from '@react-three/drei'
import useRefs from 'react-use-refs'
import {LightText1, LightText} from './Land'
import {Who} from './Who'
import {Footer} from './Footer'
import FadeMenu from './Menu'
import {Projects} from './Projects'
import Partners from './Partners'
import { Modal, Grid } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear';
import scrollDown from './images/scrollDown.png'
import services1 from './images/services1.png'
import asset from './images/asset.png'
import time from './images/time.png'
import money from './images/money.png'
import quality from './images/quality.png'
import sima from './glbs/sima.glb'
import laptop from './glbs/laptop.glb'
import mobile from './glbs/mobile.glb'
import wizbee from './images/projects/wizbee.png'
import wizbee1 from './images/projects/wizbee1.png'
import mathisiSite from './images/projects/mathisiSite.png'
import racecar from './images/projects/racecar.png'
import matrycs from './images/projects/matrycs.png'
import pulchra from './images/projects/pulchra.png'
import pulchra1 from './images/projects/pulchra1.png'
import latvianTool from './images/projects/latvianTool.png'
import automatedReport from './images/projects/automatedReport.png'

softShadows()
const rsqw = (t, delta = 0.1, a = 1, f = 1 / (2 * Math.PI)) => (a / Math.atan(1 / delta)) * Math.atan(Math.sin(2 * Math.PI * t * f) / delta)

const Comp = () => {
  return (
    <>
      <div className={`fullscreen bg ready`}>
        <div>{'Entering'}</div>
      </div>
      <span style={{color: 'white', position: 'absolute', bottom: 20, marginLeft: 20, fontSize: '1rem'}}> By entering the site you, you agree to our use of cookies. </span>
    </>
  )
}


export default function Main() {
  const [scroller, setScroller] = useState()
  const [height, setHeight] = useState()
  const [color, setColor] = useState('#222222')
  const [op, setOp] = useState(false)
  const [langEn, setLangEn] = useState(1)

  
  return (
    <>
      <Suspense fallback={<Comp/>}>
        <Canvas shadows dpr={[1, 2]} camera={{ position: [0, -3, 40], fov: 12 }} style={{backgroundColor: color, opacity: op ? 0.3 : 1}} frameloop="demand">
          <ScrollControls damping={6} pages={12}>
            <Composition langEn={langEn} scroller={scroller} setHeight={setHeight} setScroller={setScroller} setColor={setColor} op={op} setOp={setOp}/>
          </ScrollControls>
        </Canvas>
      </Suspense>
      <FadeMenu langEn={langEn} setLangEn={setLangEn} scroller={scroller} height={height} op={op} setOp={setOp}/>
    </>
  )
}



function Composition({langEn, setScroller, setHeight, scroller, setColor, op, setOp,  ...props }) {
  const scroll = useScroll()
  setScroller(scroll)
  
  const { width, height } = useThree((state) => state.viewport)

  setHeight(height)
  var tagY = 2
  var tagZ = 3.5
  if (width <= 12) {
    var globalPosition =  -height / 2.7
    var titlePos = width <= 9.5 ? 68 : 52
    var scaleLap = width / 80
    var tag11Y = 5
    var tag12Y = 3.5
    var tag13Y = 1.5
    var tag11Z = -3.5
    var tag12Z = -6
    var tag13Z = -3.4
    var tag1Height = 140
    var bubble4Y = 11
    var textAl = 'center'
    tagY = 3.5
    tagZ = 5.26
  } else if (width <= 13.5) {
    globalPosition =  -height / 2.4
    titlePos = 44.8
    tag11Y = 4.7
    tag12Y = 2.8
    tag13Y = 0.9
    tag11Z = -4
    tag12Z = -4.95
    tag13Z = -4
    tag1Height = 155
    bubble4Y = 9.8
    scaleLap = width / 75
    textAl = 'center'
    tagY = 3.5
    tagZ = 5.18
  } else { // width > 1280
    globalPosition = -height / 2.4 
    titlePos = width >= 16.5 ? 4.05 * height : 41.6
    tag11Y = 3.2
    tag12Y = 2
    tag13Y = 0.8
    tag11Z = -3
    tag12Z = -3.5
    tag13Z = -3
    tag1Height = 175
    bubble4Y = 9.5
    scaleLap = width / 88
    textAl = 'right'
  }  

  const [group, land, mbp16, mbp14, keyLight, stripLight, fillLight, bubble1, bubble3, bubble4, bubble5, button, left, left1, left2, left3, right, right2, 
          right3, right4, right5, logo, group1, group2, who, title2, titleProj, projects1, projects2, dots, buttonProj] = useRefs()
  const [services, backgroundMobile] = useTexture([services1, asset])
  const [modalMobile, setModalMobile] = useState(false)
  const [modal, setModal] = useState(false)
  const [visible, setVisible] = useState(false)
  

  const handle = () => {
    setOp(!op)
    setModalMobile(!modalMobile)
  }

  const handle1 = () => {
    setOp(!op)
    setModal(!modal)
  }

  useFrame((state, delta) => {
    const r0 = scroll.range(0 / 24, 1 / 24)
    const r1 = scroll.range(1 / 24, 2 / 24)
    const r2 = scroll.range(2 / 24, 3 / 24)
    const r3 = scroll.range(3 / 24, 4 / 24)
    const r4 = scroll.range(4 / 24, 5 / 24)
    const r5 = scroll.range(5 / 24, 6 / 24)
    const r6 = scroll.range(6 / 24, 7 / 24)
    const r7 = scroll.range(7 / 24, 8 / 24)
    const r8 = scroll.range(8 / 24, 9 / 24)
    const r9 = scroll.range(9 / 24, 10 / 18)
    const r10 = scroll.range(10 / 24, 11 / 24)
    const r11 = scroll.range(11 / 24, 12 / 24)
    const r12 = scroll.range(12 / 24, 13 / 24)
    const r13 = scroll.range(13 / 24, 14 / 24)
    const r14 = scroll.range(14 / 24, 15 / 24)
    const r15 = scroll.range(15 / 24, 16 / 24)
    const r16 = scroll.range(16 / 24, 17 / 24)
    const r17 = scroll.range(17 / 24, 18 / 24)
    const r18 = scroll.range(18 / 24, 19 / 24)
    const r19 = scroll.range(19 / 24, 20 / 24)
    const r20 = scroll.range(20 / 24, 21 / 24)
    const r21 = scroll.range(21 / 24, 21 / 24)
    const r22 = scroll.range(21 / 24, 22 / 24)
    const r23 = scroll.range(22 / 24, 23 / 24)
    const r24 = scroll.range(23 / 24, 1 / 24)
    mbp16.current.rotation.x = Math.PI - (Math.PI / 2.1) * r1 + (Math.PI / 2) * r5
    mbp14.current.rotation.x = !r1 || r1 < 1 ? Math.PI/2 : Math.PI + (Math.PI / 5) * rsqw(r1) - r4 * 0.39 - 0.2
    group.current.rotation.y = THREE.MathUtils.damp(group.current.rotation.y, -Math.PI * r5, 4, delta)
    group.current.scale.x = group.current.scale.y = group.current.scale.z = THREE.MathUtils.damp(group.current.scale.z, 1.4 + 1.1 * r1 - 1.1 * r2 + 0.2 * (1 - rsqw(r2)), 4, delta)
    keyLight.current.position.set(0.25 + -15 * (1 - r10), 4 + 11 * (1 - r10), 3 + 2 * (1 - r10))
    group.current.visible = r14 <= 0
    land.current.visible = r0 < 1 
    left1.current.classList.toggle('show', r5 && !r6)
    bubble1.current.classList.toggle('show', r5 && !r6)
    left.current.classList.toggle('show', r6 && !r7)
    bubble3.current.classList.toggle('show', r6 && !r7)
    left2.current.classList.toggle('show', r7 && !r8)
    bubble4.current.classList.toggle('show', r7 && !r8)
    left3.current.classList.toggle('show', r8 && !r9)
    bubble5.current.classList.toggle('show', r8 && !r9)
    if (r10 > 0 && ! r14 > 0) {
      setColor('#e0e0e0')
    } else {
      setColor('white')
    }
    title2.current.classList.toggle('show', r11 && !r14)
    right.current.classList.toggle('show', (r11 || r12) && !r14)
    right2.current.classList.toggle('show', (r11 || r12) && !r14)
    right3.current.classList.toggle('show', r12 && !r14)
    right4.current.classList.toggle('show', r12 && !r14)
    right5.current.classList.toggle('show', r12 && !r14)
    button.current.classList.toggle('show', r12 && !r14)
    dots.current.visible = r14 > 0 && !r16
    group1.current.visible = r16 > 0 && !r18
    who.current.classList.toggle('show', r16 && !r18)
    titleProj.current.visible = r18 > 0 && !r24
    projects1.current.visible = r18 > 0 && !r20
    projects2.current.visible = r20 > 0 && !r24
    buttonProj.current.classList.toggle('show', r22 && !r24)
    group2.current.classList.toggle('show', r24)
    if (r24 > 0) {
      setVisible(true)
    } else {
      setVisible(false)
    }    
  })


  return (
    <>
      <directionalLight ref={keyLight} castShadow intensity={5}>
        <orthographicCamera attachObject={['shadow', 'camera']} args={[-10, 10, 10, -10, 0.5, 30]} />
      </directionalLight>
      <group ref={group} position={[0, globalPosition, 0]} {...props}>
        <spotLight ref={stripLight} position={[width * 2.5, 0, width]} angle={0.19} penumbra={1} intensity={0.25} />
        <spotLight ref={fillLight} position={[0, -width / 2.4, -width * 2.2]} angle={0.2} penumbra={1} intensity={2} distance={width * 3} />
          <group ref={land} scale={width / 90}>
            <LightText/>
            <LightText1/>
            <Image/>
          </group>
          <M1 ref={mbp16} texture={services} scale={scaleLap}>
            <Tag ref={left1} position={[-22, 7, 0]} head="Impressive" stat="Design" expl={'3D Design\nGraphic Design\nUI/UX Design\nResponsiveness'} />
            <Bubble position={[-25, 15.5, 0]} ref={bubble1} text1='The most important part!' text2={['If you think ', <strong>good design</strong>, ' is expensive, you should look at the ',  <strong>cost of a bad design.</strong>]}/>
            <Tag ref={left} position={[-32, 9, 0]} head="Innovative" stat="Code" expl={'Optimized\nFast\nSecure'} />
            <Bubble position={[-33, 18, 0]} ref={bubble3} text1='Only the latest technology!' text2={['Use ', <strong>innovative technologies & frameworks </strong>, 
                                                                  'and save', <strong> time and money. </strong>]}/>
            <Tag ref={left2} position={[-80, 6, 0]} head="Uplifting" stat="Marketing" expl={`Branding\nAnalytics\nCampaigns`} />
            <Bubble position={[-114, 20, 0]} ref={bubble4} text1='Spread the word of your business!' text2={['Approach more customers and ', <strong>increase your profits </strong>, 'or analyze your customers\' needs and', <strong> build loyalty. </strong>]}/>
            <Tag ref={left3} position={[40, 4, 0]} head="Ongoing" stat="Support" expl={`Repair\nMaintenance\nImprovement`} />
            <Bubble position={[44, bubble4Y, 0]} ref={bubble5} text1="It's not what you do but how you do it!" text2={['We are ', <strong>next to you </strong>, 'by constantly', <strong> explaining processes and answering questions.</strong>]}/>
          </M1>
          <Mobile ref={mbp14} scale={width / 27} texture={backgroundMobile} rotation={[0, Math.PI/2, Math.PI]} position={[0, 0, -width / 2.625]}>
            <Title color='#222222' ref={title2} head='' position={[titlePos , 0, width < 12.7 ?  0.29 : 0.14]}/>
            <Tag color='#222222' ref={right} head='The future is' stat="Cross platform apps" expl='' position={[8, tagY, tagZ]} />
            <Tag color='#222222' ref={right2} head='' stat="" position={[7, 1, 2.5]} expl={'Simultaneous development in Android & IOS'} />
            <Tag1 color='#222222' align={textAl} height={tag1Height} ref={right3} expl={['30%', <br/>, "less time"]} position={[width/2, tag11Y, tag11Z]} icon={time}/>
            <Tag1 color='#222222' align={textAl} height={tag1Height} ref={right4} expl={['40%', <br/>, "less money"]} position={[width/2, tag12Y, tag12Z]} icon={money}/>
            <Tag1 color='#222222' align={textAl} height={tag1Height} ref={right5} expl={['100%', <br/>, "native quality"]} position={[width/2, tag13Y, tag13Z]} icon={quality}/>
            <Button ref={button} handle={handle} modalMobile={modalMobile} position={[1.5, 0.2, -4]}/>
          </Mobile>
      </group>
      <group ref={group1}>
        <Logo scroller={scroll} ref={logo} rotation={[Math.PI, Math.PI/2, 0]} position={[width / 3, 3.5, 0]} scale={width / 25} />
        <Who ref={who} position={[-width/5, 0, 0]}/>
      </group>
      <group ref={dots} scale={width / 150}>
        <Partners height={height} width={width} />
      </group>
      <group ref={titleProj}>
        <TitleMoving ref={titleProj} color='#222222' head={['Recent works Recent works Recent works Recent works Recent works Recent works Recent works Recent works']} position={[0 , height / 3.5, 2]}/>
      </group>
      <Projects title='wizbee' rand={0.2} details='UI/UX Design, Frontend/Backend Development' ref={projects1} images={[wizbee, wizbee1]}/>
      <Projects ref={projects2} rand={0} title='pulchra' details='UI/UX Design, Frontend/Backend Development' images={[pulchra1]}/>
      <Button1 ref={buttonProj} handle={handle1} modal={modal} position={[width/4, -height / 3, 1]}/>
      <Footer ref={group2} visible={visible} op={op} setOp={setOp}/> 
    </>
  )
  
}

function Image() {
  const texture = useLoader(THREE.TextureLoader, scrollDown)
  return (
    <mesh position={[-15, 10, 0]} rotation={[0, 0, 0.3]}>
      <circleBufferGeometry attach="geometry" args={[3, 50]} />
      <meshBasicMaterial attach="material" map={texture} />
    </mesh>
  )
}


/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: akshatmittal (https://sketchfab.com/akshatmittal)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/2021-macbook-pro-14-m1-pro-m1-max-f6b0b940fb6a4286b18a674ef32af2d3
title: 2021 Macbook Pro 14" (M1 Pro / M1 Max)
*/
const M1 = forwardRef(({ texture, children, ...props }, ref) => {
  const { nodes, materials } = useGLTF(laptop)
  return (
    <group {...props} dispose={null}>
      <group ref={ref} position={[0, -0.43, -11.35]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.back_1.geometry} material={materials.blackmatte} />
        <mesh receiveShadow castShadow geometry={nodes.back_2.geometry} material={materials.aluminium} />
        <mesh geometry={nodes.matte.geometry}>
          {/* <Html className='content' rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.4 , -7]} transform occlude>
            <div className='wrapper'>
              <Services/>
            </div>
          </Html> */}
          <meshLambertMaterial map={texture} toneMapped={false} />
        </mesh>
      </group>
      {children}
      <mesh geometry={nodes.body_1.geometry} material={materials.aluminium} material-color="#aaaaaf" material-envMapIntensity={0.2} />
      <mesh geometry={nodes.body_2.geometry} material={materials.blackmatte} />
    </group>
  )
})

const Mobile = forwardRef(({ texture, children, ...props }, ref) =>  {
  const { nodes, materials } = useGLTF(mobile)
  // const [video] = useState(() => Object.assign(document.createElement('video'), { src: '/drei.mp4', crossOrigin: 'Anonymous', loop: true, muted: true }))
  // useEffect(() => void (video.play()), [video])


  return (
    <group ref={ref} {...props} dispose={null} >
      <mesh geometry={nodes.Circle.geometry} material={materials.iphone_se_body} position={[0.28, 0.3, 0]} rotation={[0, 0, -Math.PI / 2]} scale={0.17} />
      {children}
      <group position={[0, 2.62, 0]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={40}>
        <mesh geometry={nodes.Mesh002.geometry} material={materials.iphone_se_black_glass} />
        <mesh geometry={nodes.Mesh002_1.geometry} material={materials.iphone_se_body}/>
        <mesh geometry={nodes.Mesh002_2.geometry} material={materials.iphone_se_black} />
        <mesh geometry={nodes.Mesh002_3.geometry}>
          {/* <meshBasicMaterial toneMapped={false} >
            <videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
          </meshBasicMaterial> */}
          <meshLambertMaterial map={texture} toneMapped={false} />
        </mesh>
        <mesh geometry={nodes.Mesh002_4.geometry} material={materials.iphone_se_glass} />
        <mesh geometry={nodes.Mesh002_5.geometry} material={materials.iphone_se_cam_lense} />
        <mesh geometry={nodes.Mesh002_6.geometry} material={materials.iphone_se_flash} />
      </group>
    </group>
  )
})

const TitleMoving = forwardRef(({ head, color, ...props }, ref) => {
  let theta = 2
  useFrame(() => {
    ref.current.position.x -= Math.sin(THREE.Math.degToRad((theta)))
    if (ref.current.position.x <= -15) {
      ref.current.position.x = 20
    }
  })

  return (
    <Text ref={ref} fontSize={1.5} letterSpacing={-0.06} font="/IsotextProLight.otf" color='#140044' {...props}>
      {head}
      <meshBasicMaterial metalness={1}/>
    </Text>
  )
})

const Title = forwardRef(({ head, color, ...props }, ref) => {
  return (
    <Html ref={ref} className="title" center {...props}>
      <h2 style={{color: color}}>{head}</h2>
    </Html>
  )
})

const Bubble = forwardRef(({ head1, head2, head3, text1, head4, text2,...props }, ref) => {
  return (
    <Html ref={ref} className="bubble" center {...props} >
      <div>
        <div style={{textAlign: 'center', marginTop: 15}}>
          <span style={{color: '#2E0099', textAlign: 'center', fontSize: '1.7vw', fontWeight: 'bold'}}> {head1}</span>
          <span style={{color: 'black', fontSize: '1.7vw', textAlign: 'center'}}> {head2} </span>
          <span style={{color: '#2E0099', fontSize: '1.7vw', fontWeight: 'bold'}}> {head3}</span>
        </div>
        <p style={{fontSize: '1.2vw', textAlign: 'center', lineHeight: '1em', margin: 5}}> {text1}</p>
        <h1 style={{color: '#2E0099', textAlign: 'center', fontSize: '2vw', fontWeight: 'bold'}}> {head4}</h1>
        <p style={{fontSize: '1.4vw', textAlign: 'justify', lineHeight: '1em', padding: 15, margin: 0}}> {text2}</p>
      </div>
    </Html>
  )
})

const Button = forwardRef(({handle, modalMobile, ...props }, ref) => {
 
  return (
    <Html className='btn' ref={ref} {...props} >
        <button onClick={handle} > More</button>
        <Modal open={modalMobile} onClose={handle}>
          <Grid container style={{width: '65vw', padding: 50, height: '90vh', background: 'white', borderRadius: 5, margin: '1vw'}}>
            <Grid item lg={12} xs={12}>
              <h1> Cross Platform mobile apps</h1>
            </Grid>
            <ClearIcon style={{color: 'gray', pointerEvents: 'stroke', cursor: 'pointer', top: '1.5vw', position: 'absolute', right: '34.5vw'}} size={30} onClick={handle}/>
            <Grid item lg={3} xs={4}>
              <h2 style={{fontSize: '1.8rem', color: '#2E0099'}}> Why? </h2>
            </Grid>
            <Grid item lg={8} xs={8}>
              <h2 style={{textAlign: 'justify'}}> Smart phones are dominating our daily life, with an average of 4.2 hours spent by global users an increase of 25% from last year and estimations show that this time will be increasing.
That means that every business should and finally will shift to mobile presence to be closer and more loyal to its customers, improve the way it serves them and subsequently increase its growth and profits.</h2>
            </Grid>
            <Grid item lg={3} xs={4}>
              <h2 style={{fontSize: '1.6rem', color: '#2E0099', marginBottom: -30}}> What <br/> Solutionist offers </h2>
            </Grid>
            <Grid item lg={8} xs={8}>
              <h2 style={{textAlign: 'justify'}}> Most developer companies deploy apps for IOS and Android separately using old technologies. <br/>
                Solutionist brings the <strong>newest technologies </strong> to market by implementing mobile apps with <strong>cross-platform frameworks </strong>, e.g <strong>frameworks that allow simultaneous implementation for both IOS and Android. </strong>
                These frameworks lead to meaningful reduction in time and money at mobile app development. </h2>
            </Grid>
          </Grid>
        </Modal>
    </Html>
  )
})



const Button1 = forwardRef(({handle, modal, mobile, ...props }, ref) => {
  const all= [
    ['https://wizbee.co', wizbee, 'Custom marketplace', 'A marketplace for a startup that aims to make scientific resources accessible to everyone.'],
    ['https://mathisi.org/racecarchallenge', racecar, 'Simple site', 'A website for Racecar Challenge, the first programming and robotics students competition in Europe, powered by MIT and Mathisi Initiative.'],
    ['https://mathisi.org', mathisiSite, 'Simple site', 'A website for Mathisi Initiative, an NGO that aims to give access to high quality, innovative educational programs, in collaboration with internationally recognized institutions.'],
    ['', matrycs, 'Custom sustainability app', 'A web app for supporting decisions of local governments related to sustainable goals, using data analytics and visualisations.'],
    ['', latvianTool, 'Custom web app', 'A web app for monitoring energy consumption and emissions of buildings in correlation with renovation activities.'],
    ['', automatedReport, 'Custom reporting desktop app', 'A desktop app for automating reports for clients regarding the production of their wind farm. Raw data is automatically converted to a pdf report that presents visualisations and statistics.', 650, 300, 280],
    ['https://play.google.com/store/apps/details?id=com.app.pulchra&hl=en_US&gl=US', pulchra, 'Cross platform mobile app', 'An app for students to network and exchange information and media about SDGs. A project funded by EU.', 650, 300, 460],
  ]

  return (
    <Html className='btn' ref={ref} {...props} >
        <button onClick={handle}> More</button>
        <Modal open={modal} onClose={handle}>
            <Grid container style={{width: '90vw', padding: 50, height: '90vh', background: 'white', borderRadius: 5, margin: '1vw', overflow: 'scroll'}}>
              <Grid item lg={12} xs={12}>
                <h1 style={{marginBottom: -30}}> Recent works </h1>
              </Grid>
              <ClearIcon style={{color: 'gray', pointerEvents: 'stroke', cursor: 'pointer', top: '1.5vw', position: 'absolute', right: '10vw'}} size={30} onClick={handle}/>
              {all.map((index, i) => 
                <div style={{borderRadius: 5, height: index[4] ? index[4] : 450, width: index[5] ? index[5] : '38vw', maxWidth: 500,background: 'white', border: '1px solid rgb(204, 204, 204)', margin: 10}} onClick={() => window.open(index[0], "_blank")}>
                    <img src={index[1]} style={{height: index[6] ? index[6] : 260, margin: '20px 20px -20px 20px', width: '90%', cursor: 'pointer'}}/>
                    <div style={{padding: '0 20px 0px 20px'}}>
                      <h3 style={{marginTop: 25}}> {index[2]} </h3>
                      <span style={{color: 'rgb(100, 100, 100)', fontSize: '1.4em'}}> {index[3]} </span>
                    </div>
                </div>
              )}
            </Grid>
        </Modal>
    </Html>
  )
})


const Tag = forwardRef(({ head, stat, color, expl, ...props }, ref) => {
  return (
    <Html ref={ref} className="data" center {...props}>
      <div style={{color: color}}>{head}</div>
      <h1 style={{color: color}}>{stat}</h1>
      <h2 >{expl}</h2>
    </Html>
  )
})

const Tag1 = forwardRef(({ head, expl, icon, color, height, align, ...props }, ref) => {
  return (
    <Html ref={ref} className="data1" center {...props} style={{backgroundColor: '#7341e80c', borderRadius: '5px', width: '10vw', height: height, minWidth: 130}}>
      <img src={icon} style={{width: 50, backgroundColor: 'white', borderRadius: '20%', padding: 10, }}/>
      <h2 style={{color: color, textAlign: align}}>{expl}</h2>
    </Html>
  )
})

const Logo = forwardRef(({children, scroller, ...props}, ref) => {
  const { nodes, materials } = useGLTF(sima)

  useFrame((state) => {
      ref.current.children.forEach((child, index) => {
        child.material.zoom = 0.9 + scroller.range(15 / 24, 17 / 24) / 2
          // child.position.x += Math.sin(index + state.clock.elapsedTime) / 400
      })
  })

  return (
      <group ref={ref} {...props} dispose={null} >
          {children}
          <mesh geometry={nodes.Cube.geometry} material={materials['Material.001']} position={[0, 4.29, -0.01]} rotation={[-Math.PI / 6, 0, 0]} scale={[0.28, 0.28, 0.28]} />
          <mesh geometry={nodes.Cube001.geometry} material={materials['Material.002']} position={[0, 4.29, -0.01]} rotation={[-0.45, 0, 0]} scale={[0.3, 0.3, 0.3]} />
          <mesh geometry={nodes.Cube002.geometry} material={materials['Material.003']} position={[0, 4.23, 0]} rotation={[-0.47, 0, Math.PI]} scale={[0.3, 0.3, -0.3]} />
      </group>
  )
})