import React, { forwardRef, useEffect, useState } from 'react'
import {Grid, Modal, TextField, MenuItem} from '@material-ui/core'
import {Html} from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import Copyright from './Copyright.js'
import { send } from '@emailjs/browser';
import ClearIcon from '@material-ui/icons/Clear';
import {AiOutlineFacebook, AiOutlineLinkedin} from 'react-icons/ai'


export const Footer = forwardRef(({op, setOp, mobile, visible, ...props}, ref) => {
    const [open, setOpen] = useState(false);
    const [sub, setSub] = React.useState('');
    const [error, setError] = useState('')
    const { width, height } = useThree((state) => state.viewport)

    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: 'Eliza Milioni',
        message: '',
        reply_to: '',
        subject: sub,
    });
    
    const handle = () => {
        setOp(!op)
        setOpen(!open)
    }

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    
    const onSubmit = (e) => {
        e.preventDefault()
        if (toSend['from_name'].length !== 0 && toSend['message'].length !== 0 && toSend['message'].length !== 0
            && toSend['subject'].length !== 0) { 
            send(
                'solutionist',
                'template_9fzkbfl',
                toSend,
                'user_8551bvXrU1SToY3gNLDxn'
            )
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    handle()
                })
                .catch((err) => {
                console.log('FAILED...', err);
                setError(err.text)
                });
        } else {
            setError('You have to fill all the values.')
        }
    }

    const subjects = [
        {
          value: 'Custom web development',
          label: 'Custom web development',
        },
        {
          value: 'Website',
          label: 'Website',
        },
        {
          value: 'Ecommerce',
          label: 'Ecommerce',
        },
        {
          value: 'Mobile app development',
          label: 'Mobile app development',
        },
        {
            value: 'Machine learning app development',
            label: 'Machine learning app development',
          },
          {
            value: 'Other',
            label: 'Other',
          },
      ];

    
    return (
        <Html center ref={ref} className='footer' {...props}> 
            <Grid container style={{width: '100vw', padding: 10, height: '98vh'}}>
                <Grid item lg={12} md={12} sx={12} sm={12} style={{marginTop: !mobile ? '12vh' : '20vh', marginBottom: mobile ? '-20vh' : null}}>
                    <h1> Contact</h1>
                </Grid>
                {!mobile && visible && width > 11.5 ? 
                    <Grid item lg={6} md={6} xs={12} style={{borderRight: '1px solid #222222', display: 'flex', height: '67vh'}}>
                        <div className="cont" onClick={handle} style={{pointerEvents: visible ? 'stroke' : 'none', cursor: visible ? 'pointer' : 'none'}}>
                            <img src='/mailIcon.svg' style={{width: 100, marginBottom: 30}}/>
                            <h2> Mail request</h2>
                            <h3> Leave your name and email and we'll contact you.</h3>
                        </div>
                        <div className="cont1" onClick={() => window.open('https://calendly.com/elizamilioni?month=2022-01', '_blank')} style={{pointerEvents: visible ? 'stroke' : 'none', cursor: visible ? 'pointer' : 'none'}}>
                            <img src='/meetIcon.svg' style={{width: 70, marginBottom: 40, marginTop: 20}}/>
                            <h2> Call request</h2>
                            <h3> Pick the time and arrange an one to one appointment.</h3>
                        </div>
                    </Grid>
                : null}
                <Grid item lg={6} md={6}  style={{paddingLeft: !mobile ? '7vw' : 0, marginTop: mobile ? -70 : '-4.5vh'}}>
                    <Grid container>
                        <Grid item lg={4} md={5} xs={4}>
                            <p style={{fontSize: mobile ? '1.2em' : '2vw', color: 'gray', marginRight: 20}}> Get in touch</p>
                        </Grid>
                        <Grid item lg={8} md={6} xs={8}>
                            <p style={{color: '#222222', fontSize: mobile ? '1rem': '2.4vw', marginTop: '5vh'}}> info@solutionist.gr</p>
                        </Grid>
                        <Grid item lg={4} md={5} xs={3}>
                            <p style={{fontSize: mobile ? '1.2em' : '2vw', color: 'gray', marginRight: 20}}> Call us</p>
                        </Grid>
                        <Grid item lg={8} md={6} xs={8}>
                            <p style={{color:  '#222222', fontSize: mobile ? '1rem': '2.4vw', marginTop: '5vh'}}> +306973518544, +306973865823</p>
                        </Grid>
                        <Grid item lg={4} md={5} xs={3}>
                            <p style={{fontSize: mobile ? '1.2em' : '2vw', color: 'gray', marginRight: 20}}> Office</p>
                        </Grid>
                        <Grid item lg={8} md={6} xs={8}>
                            <p style={{color: '#222222', fontSize: mobile ? '1rem': '2.4vw', marginTop: '5vh'}}> Alamanas 2, Galatsi, Athens, Greece</p> 
                        </Grid>
                        <Grid item xs={12} lg={4} md={5} xs={3}>
                            <p style={{fontSize: mobile ? '1.2em' : '2vw', color: 'gray', marginRight: 20}}> Social</p>
                        </Grid>
                        <Grid item lg={8} md={6} xs={8} style={{marginTop: mobile ? '3vh' : '5.8vh'}}>
                            <AiOutlineFacebook size={mobile ? 18 : 30} onClick={() =>  window.open('https://www.facebook.com/Solutionist-103597762162401', '_blank')} className='icon' style={{pointerEvents: visible ? 'stroke' : 'none', marginRight: mobile ? 0 : 20}} />
                            <AiOutlineLinkedin size={mobile ? 18 : 30} onClick={() =>  window.open('https://www.linkedin.com/company/solutionist-gr/about/?viewAsMember=true', '_blank')} className='icon' style={{pointerEvents: visible ? 'stroke' : 'none'}}/>
                        </Grid>
                    </Grid>
                </Grid>
                {!mobile ? 
                    <Grid item lg={12} md={12} style={{position: 'absolute', bottom: 0}}>
                        <Copyright/>
                    </Grid>
                : null}
                <Modal open={open} onClose={handle}>
                    <Grid container style={{width: '50%', minWidth: 500, padding: 20, height: '100vh', background: 'white', borderRadius: 5, borderColor: 'white'}}>
                        <ClearIcon size={30} style={{color: 'gray', position: 'absolute', top: 0, right: '50vw', pointerEvents: 'stroke', cursor: 'pointer'}} onClick={handle}/>
                        <Grid container style={{background: '#2E0099', color: 'white', borderRadius: 5, height: '40vh', padding: 20}}>
                            <Grid item lg={6} style={{marginTop: '5vh'}}>
                                <h1 style={{color: 'white'}}> Mail request</h1>
                            </Grid>
                            <Grid item lg={6} style={{marginTop: '4vh'}}>
                                <img src='/mailIcon.svg' style={{width: '18vw', minWidth: 70}}/>
                            </Grid>
                        </Grid>
                        <Grid item lg={12}>
                                <TextField variant='outlined' fullWidth required onChange={handleChange} name='from_name' style={{marginBottom: 20, marginTop: 20}}
                                            label='Your Name' InputLabelProps={{
                                                            shrink: true,
                                                            style: {
                                                                color: 'black'
                                                            }
                                                        }}/>
                                <TextField variant='outlined' fullWidth required onChange={handleChange} name='reply_to' style={{marginBottom: 20}}
                                            label='Your Email' InputLabelProps={{
                                                            shrink: true,
                                                            style: {
                                                                color: 'black'
                                                            }
                                                        }}/>
                                 <TextField select variant='outlined' name="subject" label="Subject" value={sub} onChange={(e) => {setSub(e.target.value); handleChange(e)}} 
                                        style={{marginBottom: 20, width: '100%',}} InputLabelProps={{
                                                                                shrink: true,
                                                                                style: {
                                                                                    color: 'black'
                                                                                }}}>
                                    {subjects.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField variant='outlined' fullWidth required onChange={handleChange} name='message' style={{marginBottom: 0}}
                                            label='Message' multiline rows={4} InputLabelProps={{
                                                            shrink: true,
                                                            style: {
                                                                color: 'black'
                                                            }
                                                        }}/>
                                <span style={{color: 'red'}}> {error} </span>
                                <br/> <br/>
                                <button className='submit' onClick={onSubmit}> Submit </button>
                        </Grid>
                    </Grid>
                </Modal>
            </Grid>
        </Html>
    )
})