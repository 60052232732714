import React, { createContext, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {URLS} from './util'
import {ThemeProvider, CssBaseline} from '@material-ui/core'   
import {theme} from './styles.js'
import Main from './Main'
import MainMobile from './MainMobile'
import {Cursor} from "./Cursor";
import GetRenders from './util'


export const CursorContext = createContext();
  

export default function App() {
  const [cursor, setCursor] = useState({ active: false });
  const mobile = GetRenders().XS

  return (
    <CursorContext.Provider value={[cursor, setCursor]}>
      {!mobile ? <Cursor /> : null}
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Router>
          <Routes>
            <Route path={URLS.HOME} element={mobile ? <MainMobile/> : <Main/>} />
          </Routes>
        </Router>
    </ThemeProvider>
  </CursorContext.Provider>
  );
}
