import {useTheme, useMediaQuery} from '@material-ui/core'

export default function GetRenders() {
    const theme = useTheme();
    return {
        XS: useMediaQuery(theme.breakpoints.down('xs')),
        DOWN_SM: useMediaQuery(theme.breakpoints.down('sm')),
        DOWN_MD: useMediaQuery(theme.breakpoints.down('md')),
        DOWN_LG: useMediaQuery(theme.breakpoints.down('lg')),
        DOWN_XL: useMediaQuery(theme.breakpoints.down('xl')),
    }
}

export const URLS = {
    PROJECTS: '/projects',
    HOME: '/'
}
