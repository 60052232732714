import { createTheme } from '@material-ui/core/styles';

export const blackText = {
  fontSize: '1rem',
  textAlign: 'justify',  
  fontWeight: 'bold'
}

export const tabs = {
  width: '100vw',
  textTransform: "none",
  paddingTop: 100
}

export const tab = {
  width: 100,
  background: 'white',
  textTransform: 'none',
  fontFamily: 'IsotexLight',
  color: 'black', 
  fontWeight: 'bold'
}

export const tabClicked = {
  background: 'linear-gradient(#7341E8, black)',
  textTransform: 'none',
  color: 'white',
  fontFamily: 'IsotexLight'
}

export const theme = createTheme({
  typography: {
    fontFamily: 'IsotextLightPro',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.2em',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#2E0099',
          outline: '1px solid slategrey',
          height: 100
        }
      }
    },
    MuiTab: {
      root: {
        fontFamily: 'Isotex',
      },
    },
    MuiIcon: {
      root: {
        overflow: 'none'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: '0.975rem',
        "&:hover": {
          backgroundColor: "transparent"
      }
      },
      text: {
        color: 'white',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      outlined: {
        backgroundColor: '#2E0099',
        color: 'white',
        borderRadius: '40px',
        textTransform: 'none',
        height: 70,
        width: '20vw',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        borderColor: 'transparent',
        '&:hover': {
          color:  '#2E0099',
          backgroundColor: 'transparent',
          border: '2px solid #2E0099',
          transitionDuration: '0.5s',
        }
      },
      contained : {
        backgroundColor: '#0492C1',
        color: 'white',
        borderRadius: '20px',
        textTransform: 'none',
        width: 200,
        height: 30,
        '&:hover': {
          color:  '#0492C1',
          backgroundColor: 'transparent' ,
          border: '2px solid #0492C1',
          transitionDuration: '0.5s',
        }
      },      
    },
    MuiGrid: {
      item: {
        margin: 0,
        padding: 0,
        paddingLeft: 30,
        position: 'relative',
        marginTop: 10
      }
    },
    MuiDivider: {
      root: {
        marginTop: 30,
        backgroundColor: '#0A50C7',
        width: 450
      }
    },
    MuiPaper: {
      elevation6: {
        boxShadow: '0px 3px 5px -1px rgb(232, 232, 232)',
      }
    },
    MuiLink: {
      underlineHover: {
        cursor: 'pointer',
        color: 'white',
        '&:hover': {
          color: '#7341E8'
        }
      }
    },
  },
  palette: {
    background: {
      default: 'white'
      // "#efefef"
    },
    primary: {
      main: '#2E0099',
    },
    secondary: {
      main: '#7341E8',
    },
  },
  
});
