import React from 'react'
import { Typography } from '@material-ui/core'


export default function Copyright({footer}) {
    return (
      <Typography color="textSecondary" align="center" style={{margin: 'auto', marginTop: 10, color: '#222222', bottom: 0}}>
        {'Copyright © '}
        {'Solutionist'} {' '}
        {new Date().getFullYear()} {' '}
      </Typography>
    );
  }