import * as THREE from 'three'
import React, { useState, useMemo, useRef } from 'react'
import { Text, Image } from '@react-three/drei'
import { useFrame  } from '@react-three/fiber'
import joule from './images/partners/joule.png'
import corallia from './images/partners/corallia.png'
import mathisi from './images/partners/mathisi.png'
import ntua from './images/partners/ntua.png'
import wootis from './images/partners/wootis.png'
import stratos from './images/partners/stratos.png'
import arhs from './images/partners/arhs.png'


  const partners = [
      [joule, 16, 3, 'https://www.joule.gr'],  
      [corallia, 15, 10, 'https://www.corallia.gr'],
      [mathisi, 13.6, 6, 'https://www.mathisi.org'],
      ['logo_iclei.svg', 7, 6, 'https://iclei-europe.org/'],
      [ntua, 8, 8, 'https://www.ntua.gr'],
      [wootis, 17.6, 4.2, 'https://www.wootis.gr'],
      [stratos, 17, 4, 'https://en.stratosplus.eu/'],
      [arhs, 17, 6, 'https://www.arhs-group.com/entities/developments-hellas/'],
      ['/escen.svg', 17, 4, 'https://www.escen.de/'],
      ['testudo.svg', 17, 5, 'https://www.testudo.gr'],
  ]
    
  const roundedSquareWave = (t, delta, a, f) => {
      return ((2 * a) / Math.PI) * Math.atan(Math.sin(2 * Math.PI * t * f) / delta)
  }
  
  function MyImage({ index, url, position, scale, link, c = new THREE.Color(), ...props }) {
    const ref = useRef()
    const [hovered, hover] = useState(false)
    const click = () => window.open(link, '_blank')
    const over = () => hover(true)
    const out = () => hover(false)
    useFrame((state, delta) => {
      ref.current.material.grayscale = THREE.MathUtils.damp(ref.current.material.grayscale, hovered ? 0 : 1, 6, delta)
      ref.current.material.color.lerp(c.set('white'), hovered ? 0.7 : 0)
    })
    return <Image url={url} ref={ref} {...props} position={position} scale={scale} onPointerOver={over} onPointerOut={out} onClick={click}/>
  }
  
  export default function Partners({height, width, mobile}) {
    const ref = useRef()

  
    const { vec, transform, positions, distances } = useMemo(() => {
      const vec = new THREE.Vector3()
      const transform = new THREE.Matrix4()
      const positions = [...Array(10000)].map((_, i) => {
        const position = new THREE.Vector3()
        position.x = (i % 100) - 50
        position.y = Math.floor(i / 100) - 50
        // Offset every other column (hexagonal pattern)
        position.y += (i % 2) * 0.5
        // Add some noise
        position.x += Math.random() * 0.3
        position.y += Math.random() * 0.3
        return position
      })
      // Precompute initial distances with octagonal offset
      const right = new THREE.Vector3(1, 0, 0)
      const distances = positions.map((pos) => {
        return pos.length() + Math.cos(pos.angleTo(right) * 8) * 0.5
      })
      return { vec, transform, positions, distances }
    }, [])
  
    useFrame(({ clock }) => {
      for (let i = 0; i < 10000; ++i) {
        const dist = distances[i]
        const t = clock.elapsedTime - dist / 25
        // Oscillates between -0.4 and +0.4
        const wave = roundedSquareWave(t, 0.15 + (0.2 * dist) / 72, 0.4, 1 / 3.8)
        // Scale initial position by our oscillator
        vec.copy(positions[i]).multiplyScalar(wave + 1.3)
        // Apply the Vector3 to a Matrix4
        transform.setPosition(vec)
        // Update Matrix4 for this instance
        ref.current.setMatrixAt(i, transform)
      }
      ref.current.instanceMatrix.needsUpdate = true
    })

    return (
      <group rotation={[0, 0, 0]} position={[0, 0, -1]} >
        <Text fontSize={6} letterSpacing={-0.06} font="/IsotextProLight.otf" color='#0f0f0f' position={[0.05 * width, 0, 4]}>
          Our collaborations
        </Text>
        {partners.map((item, i) => 
          <MyImage index={i} url={item[0]} position={[i >= 5 ? mobile ? 35 : 3.5 * width + i / 25 * 10 : mobile ? -35 : -3.5 * width + i / 25 * 10, (mobile ? 6 : 2) * height - (i % 5) * 10, 4]} scale={[item[1], item[2], 1]} link={item[3]}/>
        )}
        <instancedMesh ref={ref} args={[null, null, 10000]}>
          <circleBufferGeometry args={[0.25]} />
          <meshBasicMaterial />
        </instancedMesh>
      </group>
    )
  }
  